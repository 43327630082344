import { Axios, getAccessToken, getAccessTokenPayloadSync } from "./api";
import { MonitoringManager } from "./monitoring";
import { getPermissionsFlags } from "./unleash";
const { encode64, decode64, encodeUtf8, decodeUtf8 } =
  require("node-forge").util;

export function legacyEncode(str: String) {
  return encode64(encodeUtf8(str));
}

export function legacyDecode(encoded: string) {
  if (!encoded) return;
  return decodeUtf8(decode64(encoded));
}

export function legacyPrepOutgoingBody(body: any) {
  return legacyEncode(JSON.stringify(body));
}

export function legacyTreatIncomingRes(res: { [key: string]: any }) {
  try {
    return { ...res, data: JSON.parse(legacyDecode(res.data)) };
  } catch (e) {
    const error = new Error("Legacy Decode Error");
    const projectDsn = MonitoringManager.utilityProjectDsn;
    MonitoringManager.captureException(projectDsn, error, "error", {
      response: res,
      error: e,
    });
    return res;
  }
}

export function legacyGetFromLS(key: string) {
  const value = legacyDecode(localStorage.getItem(legacyEncode(key)));
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

export function legacySetInLS(key: string, value: any) {
  localStorage.setItem(legacyEncode(key), legacyEncode(JSON.stringify(value)));
}

export function setLegacyStorageData(data: { [key: string]: any }) {
  const startedEvent = new CustomEvent("legacyLSUpdated", {
    detail: "started",
  });
  const finishedEvent = new CustomEvent("legacyLSUpdated", {
    detail: "finished",
  });
  dispatchEvent(startedEvent);
  const requiredKeys = ["_id", "nameAssociated", "companyId"];

  if (!requiredKeys.every((key) => data[key])) throw new Error("server");

  const storageData = {
    type: "company",
    timestamp: Date(),
    adminId: data._id,
    adminName: data.nameAssociated,
    companies: data.companyId,
    emailAssociated: data.emailAssociated,
    env: data.env,
    superUser: data.superUser,
    userSteps: data.userSteps || {},
    steps: data.steps || {},
  };
  legacySetInLS("clientData", storageData);
  legacySetInLS("authData", data.auth);

  dispatchEvent(finishedEvent);
  return storageData;
}

function checkCallbackAndDispatchEvent(event: CustomEvent, callback?: any) {
  if (typeof callback === "function") callback.call();
  dispatchEvent(event);
  return;
}

export async function switchLegacyStorageData(cnpj: string, callback?: any) {
  const startedEvent = new CustomEvent("legacyLSUpdated", {
    detail: "started",
  });
  dispatchEvent(startedEvent);
  const finishedEvent = new CustomEvent("legacyLSUpdated", {
    detail: "finished",
  });

  const updatedData = await fetchLegacyLSData();
  if (!updatedData) {
    return checkCallbackAndDispatchEvent(finishedEvent, callback);
  }

  const clientData = setLegacyStorageData(updatedData?.data);
  const fetchData = clientData?.companies?.find(
    (company) => company.cnpj === cnpj
  );
  if (!fetchData) {
    const clearedClientData = { ...clientData };

    legacySetInLS("clientData", { ...clearedClientData });
  } else {
    const {
      _id,
      companySteps,
      featureConfig,
      groupId,
      haveExpenseManagement,
      havePlasticActiveBenefit,
      haveVirtualActiveBenefit,
      hasContracts,
      invoice,
      isOldStructure,
      path,
      permissions,
      reports,
    } = fetchData;

    legacySetInLS("clientData", {
      ...clientData,
      companyId: _id,
      companySteps,
      featureConfig,
      groupId,
      haveExpenseManagement,
      havePlasticActiveBenefit,
      haveVirtualActiveBenefit,
      hasContracts,
      invoice,
      isOldStructure,
      path,
      permissions,
      reports,
    });
    legacySetInLS("authData", updatedData?.data?.auth);
  }

  return checkCallbackAndDispatchEvent(finishedEvent, callback);
}

export function refreshLegacyStorageAuth(token: string) {
  const updatedAuth = { idToken: token };
  legacySetInLS("authData", updatedAuth);
  return;
}

export async function fetchLegacyLSData() {
  const { economicGroupId } = getAccessTokenPayloadSync();
  const { flagIsEnabled } = await getPermissionsFlags("", "", economicGroupId);
  const usingAccessManagementPermissions = flagIsEnabled(
    "HR_XP_ACCESS_MANAGEMENT_PERMISSIONS_LOGIN"
  );
  try {
    const accessToken = await getAccessToken();
    const options = usingAccessManagementPermissions
      ? {
          service: "backwardMigration",
          url: "client-data",
        }
      : {
          service: "legacyCompany",
          url: "company/commutedLogin",
        };

    const response = await Axios({
      method: "post",
      data: legacyPrepOutgoingBody({
        accessToken,
      }),
      axiosOptions: {
        removeAllowOrigin: true,
      },
      ...options,
    });

    return legacyTreatIncomingRes(response);
  } catch (e) {
    const requestTo = usingAccessManagementPermissions
      ? "client-data"
      : "commutedLogin";
    const error = new Error(`Fetch Legacy Data Error - ${requestTo}`);
    console.log(error);
    const projectDsn = MonitoringManager.utilityProjectDsn;
    MonitoringManager.captureException(projectDsn, error, "error", {
      error: e,
    });
    return undefined;
  }
}
