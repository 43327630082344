import axios from "axios";
import { Buffer } from "buffer";
import { navigateToUrl } from "single-spa";
import { Auth } from "./auth";
import { getFromLS, setInLS } from "./localStorage";
import { refreshLegacyStorageAuth } from "./legacyUtility";

import { services, gatewayServices } from "./services";
import { appendRedirectUrlToPath, parseJwt } from "./utils";

const buildEnv = process.env.BUILD_ENV;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers["Access-Control-Allow-Origin"] = "*";

    const axiosOptions = config as any;
    const isGatewayService =
      gatewayServices.indexOf(axiosOptions.service) !== -1;
    const authorizationHeaderKey = isGatewayService
      ? "X-Flash-Auth"
      : "Authorization";

    if (axiosOptions?.noHeaders) return config;

    if (axiosOptions?.removeAllowOrigin) {
      delete config.headers["Access-Control-Allow-Origin"];
    }

    if (axiosOptions?.externalToken) {
      config.headers[authorizationHeaderKey] = `Bearer ${
        axiosOptions?.externalToken || null
      }`;
      return config;
    }

    if (axiosOptions?.cognitoToken) {
      try {
        const currentSession = await Auth.currentSession();
        const cognitoToken = currentSession.getIdToken().getJwtToken();
        config.headers[authorizationHeaderKey] = `Bearer ${cognitoToken}`;

        return config;
      } catch (error) {
        navigateToUrl("/authentication/login");
      }
    }

    const accessTokenRefreshed = await getAccessToken();

    if (accessTokenRefreshed)
      config.headers[authorizationHeaderKey] = `Bearer ${accessTokenRefreshed}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

interface AxiosProps {
  service: string;
  method: string;
  url: string;
  axiosOptions?: { [key: string]: any };
  data?: { [key: string]: any };
}

export const Axios = async ({
  service,
  method,
  url,
  axiosOptions,
  data,
}: AxiosProps) => {
  const options = {
    ...{ baseURL: services[service][buildEnv] },
    ...{ method },
    ...{ url },
    ...{ service },
    ...axiosOptions,
    ...{ data },
  };
  return await axiosInstance(options);
};

interface parseJWTProps {
  token: string;
}

export const parseJWT = ({ token }: parseJWTProps) => {
  try {
    var base64Payload = token.split(".")[1];
    var payload = Buffer.from(base64Payload, "base64");

    return JSON.parse(payload.toString());
  } catch (e) {
    return null;
  }
};

const epochDateCompare = ({ exp }) => {
  const now = Date.now().valueOf() / 1000 - 15;
  return exp <= now;
};

type AcessToken = {
  accessToken: string;
  company: {
    id: string;
    name: string;
  };
};

export const getAccessTokenLocal = (): AcessToken | undefined => {
  const accessToken = getFromLS("hrosAccessToken");
  return accessToken as AcessToken;
};

export const setAccessTokenLocal = (accessToken: AcessToken) => {
  setInLS({
    key: "hrosAccessToken",
    value: accessToken,
  });
};

export const refreshAccessToken = async () => {
  const { Authentication } = await import("@flash-hros/auth-helper");

  const hrosAccessToken = getAccessTokenLocal() || {
    accessToken: "",
    company: null,
  };

  try {
    const parsedToken = parseJWT({ token: hrosAccessToken.accessToken });

    const isExpired = epochDateCompare({ exp: parsedToken.exp });

    let updatedToken = null;
    if (isExpired) {
      updatedToken = await Authentication.getAccessToken({
        companyId: hrosAccessToken.company.id,
      });
      refreshLegacyStorageAuth(
        updatedToken?.tokenId || hrosAccessToken.accessToken
      );
    }

    return updatedToken?.accessToken || hrosAccessToken.accessToken;
  } catch (err) {
    return hrosAccessToken.accessToken;
  }
};

export type AccessTokenPayload = {
  economicGroupId: string;
  employeeId: string;
  role: string;
  companyId: string;
  exp: number;
  sub: string;
};

/**
 * Get the access token payload refreshed
 * @returns AccessTokenPayload
 */
export const getAccessTokenPayload = async () => {
  const accessToken = await getAccessToken();
  return parseJwt(accessToken) as AccessTokenPayload;
};

/**
 * This function returns the local access token without validating it.
 * Be carefull, just use it for frontend solutions!
 * @returns AccessTokenPayload
 */
export const getAccessTokenPayloadSync = () => {
  const accessToken = getAccessTokenLocal();
  if (!accessToken?.accessToken) return null;
  return parseJwt(accessToken?.accessToken) as AccessTokenPayload;
};

/**
 * Return the access token from local if its still valid or fetch a new one on a remote service.
 * @returns a valid access token
 */
export const getAccessToken = async (): Promise<string> => {
  let IDToken: any;

  try {
    const currentSession = await Auth.currentSession();
    IDToken = currentSession.getIdToken();
  } catch {
    navigateToUrl(appendRedirectUrlToPath("/authentication/login"));
    return "";
  }

  try {
    const accessToken = getAccessTokenLocal();

    if (!accessToken) {
      navigateToUrl(
        appendRedirectUrlToPath("/authentication/access-selection")
      );
      return "";
    }

    const accessTokenPayload = parseJwt(
      accessToken.accessToken
    ) as AccessTokenPayload;

    // Current stored access token does not belong to the logged in user
    if (IDToken.payload.sub !== accessTokenPayload.sub) {
      navigateToUrl(
        appendRedirectUrlToPath("/authentication/access-selection")
      );
      return "";
    }

    const isExpired = epochDateCompare({ exp: accessTokenPayload.exp });
    if (!isExpired) {
      return accessToken.accessToken;
    }

    const baseUrl =
      buildEnv === "production"
        ? "https://hros-access-management.us.flashapp.services"
        : "https://hros-access-management.private.flashapp.dev";
    const response = await axios.post<{
      data: { accessToken: string; company: { id: string; name: string } };
    }>(
      `${baseUrl}/tokens`,
      { companyId: accessTokenPayload.companyId },
      { headers: { Authorization: `Bearer ${IDToken.getJwtToken()}` } }
    );

    setAccessTokenLocal(response.data.data);

    return response.data.data.accessToken;
  } catch (error) {
    await Auth.signOut();
    navigateToUrl(appendRedirectUrlToPath("/authentication/access-selection"));
    return "";
  }
};
