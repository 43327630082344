import { AnalyticsManager } from "./analytics";
import { getAccessTokenPayloadSync } from "./api";
import { getAuthenticatedUser } from "./auth";
import { EmployeePermissions } from "./custom-hooks/types";
import { getFromLS } from "./localStorage";

const EMPLOYEE_PERMISSIONS_LS_KEY = "employee_permissions";
const USER_AUTHENTICATED_EVENT = "user_authenticated";
const EMPLOYEE_PERMISSIONS_UPDATED_EVENT = "employee_permissions_updated";
const EMPLOYEE_ACCESS_SELECTED_EVENT = "employee_access_selected";

export const StartAnalyticsObserver = () => {
  setupIdentify();

  window.addEventListener(USER_AUTHENTICATED_EVENT, () => {
    setupIdentify();
  });
  window.addEventListener(EMPLOYEE_PERMISSIONS_UPDATED_EVENT, () => {
    setupIdentify();
  });
  window.addEventListener(EMPLOYEE_ACCESS_SELECTED_EVENT, () => {
    setupIdentify();
  });

  async function setupIdentify() {
    try {
      const user = await getAuthenticatedUser();
      if (user) {
        const accessToken = getAccessTokenPayloadSync();
        const permissions = getCachedPermissions();
        const companyPermissions = permissions?.companies?.find(
          (c) => c.id === accessToken?.companyId
        );
        const isSuperAdmin = permissions?.isAdmin;
        const isCompanyAdmin = !!companyPermissions.permissions?.length;

        if (isSuperAdmin || isCompanyAdmin) {
          AnalyticsManager.shared.identify(user.sub, {
            employeeId: accessToken?.employeeId,
            permissions: companyPermissions?.permissions,
            companyId: accessToken?.companyId,
            economicGroupId: accessToken?.economicGroupId,
            email: user?.email,
            isSuperAdmin,
            isCompanyAdmin,
          });
        }
      }
    } catch (e) {
      //
    }
  }
};

const getCachedPermissions = () =>
  getFromLS(EMPLOYEE_PERMISSIONS_LS_KEY) as EmployeePermissions;
