import { AnalyticEventType, StringMap } from "../AnalyticEventType";
import { IAnalyticsProvider } from "../IAnalyticsProvider";

export class PendoProvider implements IAnalyticsProvider {
  initialized: boolean = false;

  track<T extends AnalyticEventType<string>>(event: T): void {
    try {
      // @ts-ignore
      pendo.track(event.name, event.params);
    } catch {}
  }

  identify(id: string, properties?: StringMap): void {
    try {
      const props = this.createPendoIdentity(id, properties);
      if (!this.initialized) {
        // @ts-ignore
        pendo.initialize(props);
        this.initialized = true;
        return;
      }
      // @ts-ignore
      pendo.identify(props);
    } catch {}
  }

  private createPendoIdentity(userId?: string, properties?: StringMap) {
    const { companyId, economicGroupId, ...visitorProps } = properties || {};

    return {
      visitor: {
        id: userId,
        ...visitorProps,
      },
      account: {
        id: companyId,
        companyId,
        economicGroupId,
      },
    };
  }

  setPageEvent<T extends AnalyticEventType<string>>(event: T): void {
    // pendo does not have set page implementation
  }
}
