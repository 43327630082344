import { useEffect, useMemo, useState } from "react";

export enum VIEW_MODE_ENUM {
  ECONOMIC_GROUP = "ECONOMIC_GROUP",
  COMPANY = "COMPANY",
}

const enabledRoutes = ["/home/admin", "/employees"];

export const useViewMode = () => {
  const localStorageKey = "viewMode";

  // TODO - Use LS methods
  const storedView = localStorage.getItem(
    localStorageKey
  ) as VIEW_MODE_ENUM | null;

  const [view, setView] = useState<VIEW_MODE_ENUM>(() => {
    return storedView && Object.values(VIEW_MODE_ENUM).includes(storedView)
      ? storedView
      : VIEW_MODE_ENUM.COMPANY;
  });

  useEffect(() => {
    localStorage.setItem(localStorageKey, view);
    window.dispatchEvent(new Event("storage"));
  }, [view]);

  useEffect(() => {
    const handleStorageChange = () => {
      const newView = localStorage.getItem(
        localStorageKey
      ) as VIEW_MODE_ENUM | null;
      if (newView && Object.values(VIEW_MODE_ENUM).includes(newView)) {
        setView(newView);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      if (!enabledRoutes.includes(window.location.pathname)) {
        setView(VIEW_MODE_ENUM.COMPANY);
      }
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const toggle = () => {
    setView((prev) =>
      prev === VIEW_MODE_ENUM.COMPANY
        ? VIEW_MODE_ENUM.ECONOMIC_GROUP
        : VIEW_MODE_ENUM.COMPANY
    );
  };

  return { mode: view, setMode: setView, toggle };
};
